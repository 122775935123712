import { render, staticRenderFns } from "./integrationnav.vue?vue&type=template&id=da4e8522&scoped=true"
import script from "./integrationnav.vue?vue&type=script&lang=js"
export * from "./integrationnav.vue?vue&type=script&lang=js"
import style0 from "./integrationnav.vue?vue&type=style&index=0&id=da4e8522&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da4e8522",
  null
  
)

export default component.exports