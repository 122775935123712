<template>
    <div class="integration">
        <img class="boximg" src="../../assets/integration/1.png" alt="" srcset="">
        <jobnav name="幼儿园" />
        <div class="navmargin">
            <integrationnav name="新型公办幼儿园" />
        </div>
        <div class="garten">
            <div class="gartenleft" @click="scrollLeft">
                <div class="gartenlefticon">
                    <img src="../../assets/integration/kindergarten/icon.png" alt="">
                </div>
            </div>

            <div class="gartenbox" ref="garten">
                <div class="gartenone" v-for="(item,index) in gartenimg" :key="index">
                    <div class="gartenimg">
                        <img :src="item.img" alt="">
                    </div>
                    <div class="gartenimgback">
                        <div class="gartenimgbackname">{{item.name}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="navmargin">
            <integrationnav name="园所环境" />
        </div>
        <div class="hjbox">
            <div class="hjswiper">
                <el-carousel :interval="4000" :height="flexshow?'260px':'360px'">
                    <el-carousel-item class="hjswiperone">
                        <div class="hjswiperimg" v-for="(item,index) in environment" :key="index">
                            <img :src="item" alt="" srcset="">
                        </div>
                    </el-carousel-item>
                    <el-carousel-item class="hjswiperone">
                        <div class="hjswiperimg" v-for="(item,index) in environment1" :key="index">
                            <img :src="item" alt="" srcset="">
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>
        <jobnav name="托育园" />
        <div class="torr">
            <div class="torrheader">
                <img src="../../assets/integration/2.png" alt="">
            </div>
            <div class="torrmain">
                <div class="torrmainone">
                    <img src="../../assets/integration/3.png" alt="">
                </div>
                <div class="torrmainone">
                    <img src="../../assets/integration/4.png" alt="">
                </div>
            </div>
        </div>
        <jobnav name="园所荣誉" />
        <div class="rongyubox">
            <div class="rongyuimgone" v-for="(item,index) in imglist" :key="index">
                <img :src="item" alt="">
            </div>
        </div>
    </div>
</template>
<script>
import jobnav from "../../components/jobnav.vue";
import integrationnav from "../../components/integrationnav.vue";
import alljson from "../../store/alljson";

export default {
    components: {
        jobnav,
        integrationnav,
    },
    data() {
        return {
            imglist: [
                require("../../assets/integration/rongyu/1.png"),
                require("../../assets/integration/rongyu/2.png"),
                require("../../assets/integration/rongyu/3.png"),
                require("../../assets/integration/rongyu/4.png"),
                require("../../assets/integration/rongyu/5.png"),
                require("../../assets/integration/rongyu/6.png"),
                require("../../assets/integration/rongyu/7.png"),
                require("../../assets/integration/rongyu/8.png"),
            ],
            gartenimg: [],
            timer: "",
            environment: [],
            environment1: [],
            flexshow:false
        };
    },
    created() {
        var pageWidth =
            document.documentElement.clientWidth || document.body.clientWidth;
        if (pageWidth < 1920) {
            this.flexshow = true;
        } else {
            this.flexshow = false;
        }
        this.gartenimg = alljson.kindergarten;
        this.environment = alljson.environment;
        this.environment1 = alljson.environment1;
    },
    methods: {
        scrollLeft() {
            this.$refs.garten.scrollLeft += 700;
        },
       
    },
};
</script>
<style lang="scss" scoped>
.integration {
    .boximg {
        width: 100%;
        margin-bottom: 30px;
    }
    .rongyubox {
        width: 70vw;
        display: grid;
        grid-template-columns: auto auto auto auto;
        margin: 30px auto 30px;
        grid-gap: 20px;
        .rongyuimgone {
            width: 100%;
            img {
                width: 100%;
            }
        }
    }
    .navmargin {
        width: 70vw;
        margin: 30px auto;
    }
    .garten {
        display: flex;
        margin-bottom: 30px;
        .gartenleft {
            height: 250px;
            background: rgba(8, 65, 144, 0.06);
            width: 210px;
            display: flex;
            align-self: center;
            justify-content: flex-end;
            padding-right: 40px;
            .gartenlefticon {
                align-self: center;
                width: 60px;
                img {
                    width: 100%;
                }
            }
        }
        .gartenbox {
            width: calc(100% - 250px);
            display: flex;
            overflow-y: hidden;
            overflow-x: scroll;
            &::-webkit-scrollbar {
                width: 10px;
                height: 4px;
            }

            &::-webkit-scrollbar-thumb {
                background: #ddd; // 滑块颜色
                border-radius: 2px; // 滑块圆角
            }

            &::-webkit-scrollbar-thumb:hover {
                background: #ccc; // 鼠标移入滑块
            }
            .gartenone {
                width: 630px;
                height: 460px;
                position: relative;
                margin-right: 20px;
                .gartenimg {
                    width: 630px;
                    height: 430px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .gartenimgback {
                    position: absolute;
                    bottom: 0;
                    left: -50%;
                    right: -50%;
                    width: 564px;
                    height: 70px;
                    margin: 0 auto;
                    background-image: url("../../assets/integration/kindergarten/back.png");
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    background-position: center;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                    .gartenimgbackname {
                        font-size: 20px;
                        text-align: center;
                        line-height: 70px;
                        color: #ffffff;
                    }
                }
            }
        }
        .gartenbox::-webkit-scrollbar-track {
            display: none;
        }
    }
    .hjbox {
        // height: 334px;
        background: rgba(190, 65, 63, 0.06);
        padding: 30px 0 20px;
        width: 100%;
        margin-bottom: 30px;
        .hjswiper {
            width: 70vw;
            margin: 0 auto;

            .hjswiperone {
                width: 100%;
                display: flex;
                justify-content: space-between;
                .hjswiperimg {
                    width: 33%;
                    img {
                        width: 100%;
                    }
                }
            }
            /deep/.el-carousel__button {
                background: #be413f;
            }
        }
    }
    .torr {
        width: 70vw;
        margin: 30px auto 30px;
        .torrheader {
            width: 100%;
            margin-bottom: 20px;

            img {
                width: 100%;
            }
        }
        .torrmain {
            width: 100%;
            display: flex;
            justify-content: space-between;
            .torrmainone {
                width: 49%;
                img {
                    width: 100%;
                }
            }
        }
    }
}
</style>